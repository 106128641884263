.root {
  @apply px-3 py-2 px-2 h-[42px] text-base min-w-24 hover:opacity-80 bg-white border rounded-sm border-border hover:border-secondary m-1 inline-flex justify-center items-center gap-[2px] align-middle leading-normal;
  @apply 2xl:text-sm mxl:min-w-[5.2rem];
}
.active {
  @apply border-secondaryButton bg-secondaryButton/5 hover:border-black shadow-[inset_0_0_0_0.5px_#000];
  /*bg-tableRowHover*/
}
.btnPrimary {
  /* @apply text-white bg-[linear-gradient(180deg,rgba(161,32,78,0.85),#a1204e)] border-primary hover:border-primary; */
  @apply text-white bg-primaryButton hover:!bg-secondaryButton duration-150 active:!bg-tertiary border-0 rounded-sm !opacity-100;
}
.btnPrimary.isDisabled,
.btnPrimary[disabled] {
  @apply text-white !opacity-50 !border-primary;
}
.active.isDisabled,
.active[disabled] {
  @apply !border-black text-black;
}
.btnSecondary {
  @apply w-full bg-white text-black hover:bg-gray-100 cursor-pointer tracking-wider border-secondary duration-150 active:!bg-white disabled:bg-border disabled:hover:bg-border;
}
.btnIcon {
  @apply w-[52px] lg:w-12 md:w-10 h-[52px] lg:h-12 md:h-10 min-w-0 border-0;
}
.isDisabled,
button[disabled] {
  @apply !opacity-100 text-gray-300 hover:border-border select-none cursor-not-allowed;
}
.isDisabled > img,
button[disabled] > img {
  @apply opacity-50 pointer-events-none;
}
.loader {
  @apply !size-auto relative !bg-transparent
}